export function initWpk(street) {
    try {
        let config;
        //蒋村街道
        if (street === 12573) {
            config = {
                bid: 'wggg_zzdpro',
                signkey: '1234567890abcdef',
                gateway: 'https://wpk-gate.zjzwfw.gov.cn'
            };
        }
        const wpk = new wpkReporter(config);
        wpk.installAll();
        window._wpk = wpk;
    } catch (err) {
        console.error('WpkReporter init fail', err);
    }
}
